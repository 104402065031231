import React from 'react';
import { useQuery } from '@tanstack/react-query';
import useAxios from '../../hooks/useAxios';

const AllProjectReport = () => {
  const api = useAxios();

  const { data } = useQuery({
    queryKey: ['all-project-report'],
    queryFn: () =>
      api.get(`api/report/all-project`).then((res) => {
        return res.data.data;
      }),
  });

  console.log(data);

  return (
    <table className="table bordered">
      <thead>
        <thead>
          <th>#</th>
          <th>Project Number</th>
          <th>Project Name</th>
          <th>Goals</th>
          <th>Start Date</th>
          <th>End Date</th>
        </thead>
        <tbody>
          {data?.map((d, i) => (
            <>
              <tr key={d.project_id}>
                <td>{i + 1}</td>
                <td>{d.project_number}</td>
                <td>{d.project_name}</td>
                <td>{d.goals}</td>
                <td>{d.start_date}</td>
                <td>{d.end_date}</td>
              </tr>
              {d.task.map((t, it) => (
                <tr key={t.task_id}>
                  <td></td>
                  <td>{it + 1}</td>
                  <td colSpan="2">{t.task_title}</td>
                  <td>{t.start_date}</td>
                  <td>{t.end_date}</td>
                  <td></td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </thead>
    </table>
  );
};

export default AllProjectReport;
